<template>
  <v-container class="">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row class="text-center pt-16">
      <v-col>
        <h1>Fast geschafft!</h1>
        <h2>Bitte bestätige Deine E-Mail Adresse.</h2>
        <v-img
            :src="require('@/assets/images/drawSVGs/mailbox.svg')"
            max-width="200px"
            class="d-inline-flex mt-6"
            style="transform: scaleX(-1);"/>

        <p>Du hast keine E-Mail bekommen? Wenn es auch nach erneutem Versuch nicht funktioniert kontaktiere uns gerne!</p>
        <v-btn @click="resendMail" class="primary">E-Mail erneut versenden</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {error, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "PleaseVerifyMail",
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    })
  },
  components:{
    Message
  },
  data() {
    return {
      message: {
        show: false,
        text: "",
        color: ""
      },
    }
  },
  methods: {
    resendMail(){
      this.$store.dispatch('auth/verifyMail').then(() => {
        this.message = success('Die E-Mail wurde erneut versendet!')
      }).catch((err) => {
        this.message = error(err)
      })
    }
  },
  mounted() {
      setTimeout(() => {
        if(this.user.emailVerification){
          this.message.show = false
          this.$router.push('/planner/dashboard?newAccount=true')
        }
      }, 1500);
  }
}
</script>

<style scoped>
v-row {
  width: 100vw !important;
}

h1 {
  font-weight: 300;
}

v-card {
  backdrop-filter: blur(14px) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.background {
  background-image: url(https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80);
  height: 102vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-row {
  top: 50%;
  display: flex;
}

.transparent-card {
  backdrop-filter: blur(9px) !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
